import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { StyledCorporateLogos, StyledCorporateLogoList, StyledCorporateLogoListItem } from '../../styledComponents/corporateLogoList'
const CoroporateLogoList = () => {
  return (
    <StyledCorporateLogos>
    <StyledCorporateLogoList>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/universal-music-group.png" alt="Universal Music Group" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/monica-vinader.png" alt="Monica Vinader Jewellery" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/department-of-energy-and-climate-change.png" alt="Department of Energy and Climate Change logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/jaaq-logo.png" alt="JAAQ - Just ask a question logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/linac_learning_logo.png" alt="Linac Learning" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/immediate-media.png" alt="Immediate media logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/genuine-dining.png" alt="Genuine Dining logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/birch-community.png" alt="Birch Community logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/hoodrich.png" alt="Hoodrich logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/tailify.png" alt="Tailify logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/situlive.png" alt="Situ Live logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/westfield.png" alt="Westfield logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/societe-generale.png" alt="Societe Generale logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/groundswell.png" alt="Groundswell logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/partnership-counsel.png" alt="Partnership Counsel logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/trust-for-developing-comunities.png" alt="Trust for Developing Comunities logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/energize-learning.png" alt="Energizing learning logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/the-big-retreat-festival.png" alt="The Big Retreat Festival logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/i-am-me-logo.png" alt="I am me logo" /></StyledCorporateLogoListItem>
        <StyledCorporateLogoListItem><StaticImage loading="lazy" src="../../images/corporate/fhtj_logo.png" alt="From Heartache to Joy logo" /></StyledCorporateLogoListItem>
    </StyledCorporateLogoList>
    </StyledCorporateLogos>
  )
}

export {
    CoroporateLogoList
}