import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"

const StyledGalleryWrapper = styled.div`
    .alice-carousel__dots {
        margin-bottom: 1.6rem;
        margin-top: 1.6rem;
    }
    .alice-carousel__stage {
        margin-top: 1.6rem;
        vertical-align: middle;
        text-align: center;
    }
    .alice-carousel__stage-item {
        vertical-align: middle;
    }
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        display: none;
    }
    li img {
        width: 140px;
        vertical-align: center;
        text-align: center;

        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 180px;
        }
    }

    .alice-carousel__dots {
        position: relative;
    }
    .alice-carousel__dots-item:not(.__custom):hover, 
    .alice-carousel__dots-item:not(.__custom).__active {
        background: none;
    }
    .alice-carousel__dots-item {
        position: relative;
        background: none;
        &:before {
            content: " ";
            position: absolute;
            border: 0.3rem solid var(--grey-mid);
            background: #fff;
            width: 1.7rem;
            height: 1.7rem;
            top: 0;
            border-radius: 50%;
            left: 0;
        }

        &:hover {
            &:before {
                background: var(--grey-mid-dark);
                border: 0.3rem solid var(--grey-mid-dark);
            }
        }
        &:not(.__custom).__active {
            &:before {
                background: var(--grey-mid-dark);
                border: 0.3rem solid var(--grey-mid-dark);
            }
        }
    }
`

export {
    StyledGalleryWrapper
}