import styled from "styled-components"
import breakpoints from "./breakpoints"
import {
  regular14,
  regular16,
  semibold14,
  semibold16,
} from "./typography"
import { baseGridStyles } from "./base";

const StyledCorporateLogos = styled.div`
display: flex;
flex-wrap: wrap;
transform: scale(1);    
`

const StyledCorporateLogoList = styled.ul`
display: flex;
flex-wrap: wrap;
list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    @media screen and (min-width: ${breakpoints.md}px) {
        max-width: 130rem;
        margin: 0 auto;
    }
`

const StyledCorporateLogoListItem = styled.li`
width: 50%;
margin: 2rem 0;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
justify-content: center;
align-items: center;

@media screen and (min-width: ${breakpoints.sm}px) {
    width: 33.333333334%;
    margin: 1.5rem 0;
}

@media screen and (min-width: ${breakpoints.md}px) {
    width: 25%;
    margin: 3rem 0;
}

 img {
    display: block;
    width: 100%;
    height: auto;
 }
`

export {
    StyledCorporateLogos,
    StyledCorporateLogoList,
    StyledCorporateLogoListItem
}